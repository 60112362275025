"use client";

import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";

import styles from "./Footer.module.scss";
import { HydraTextIconWhite } from "@/components/Icons/HydraTextIcon";

export const Footer: React.FC = (props) => {
  const t = useTranslations("Common"); // default namespace (optional)
  return (
    <footer className={[styles.footer].join(" ")}>
      <Link
        href={"/"}
        className={[styles.footerLogo].join(" ")}
        aria-label="Link to the home page"
      >
        <HydraTextIconWhite className="h-[3rem] w-auto mb-[0.5rem]" />
      </Link>
      <ul
        className={[
          styles.footerLinkList,
          "flexContainer",
          "flexRow",
          "flexWrap",
        ].join(" ")}
      >
        <li>
          <Link href={"/about"}>{t("footer-aboutLink")} </Link>
        </li>
        <li>
          <Link href={"/#contact"}>{t("footer-contactLink")} </Link>
        </li>
        <li>
          <Link href={"/imprint"}>{t("footer-imprintLink")}</Link>
        </li>
        <li>
          <Link href={"/privacy"}>{t("footer-privacyLink")}</Link>
        </li>
      </ul>
    </footer>
  );
};
