"use client";

import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { usePlausible } from "next-plausible";
import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  NavbarMenuItem,
} from "@nextui-org/navbar";
import { Link } from "@nextui-org/link";

import { siteConfig } from "@/config/site";
import HydraTextIcon from "@/components/Icons/HydraTextIcon";

export const Navbar = () => {
  const t = useTranslations("Common"); // default namespace (optional)
  return (
    <NextUINavbar maxWidth="xl" position="sticky" isBlurred={false}>
      <NavbarContent className="basis-full" justify="start">
        <NavbarBrand as="li" className="gap-3 max-w-fit">
          <Link
            className="flex items-center gap-1"
            href="/"
            aria-label="Click link to go to landing page home"
          >
            <HydraTextIcon
              style={{ height: "2rem", width: "auto", fill: "#55555" }}
            />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent justify="end">
        <ul className="hidden md:flex lg:flex gap-4 justify-end ml-2 align-end">
          {siteConfig.navItems.map((item) => (
            <NavbarItem key={item.href}>
              <Link
                color="primary"
                className="hover:underline hover:opacity-100"
                href={item.href}
                aria-label={item.ariraLabel}
              >
                {t(item.label)}
              </Link>
            </NavbarItem>
          ))}
        </ul>
      </NavbarContent>

      <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarMenu>
        <div className="mx-4 mt-2 flex flex-col gap-2">
          {siteConfig.navItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link
                color="primary"
                href={item.href}
                aria-label={item.ariraLabel}
                size="lg"
              >
                {t(item.label)}
              </Link>
            </NavbarMenuItem>
          ))}
        </div>
      </NavbarMenu>
    </NextUINavbar>
  );
};
