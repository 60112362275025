export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "Next.js + NextUI",
  description: "Make beautiful websites regardless of your design experience.",
  navItems: [
    {
      label: "header-aboutLink",
      href: "/about",
      ariraLabel: "bounty-list-view-link",
    },
    {
      label: "header-contactLink",
      href: "/#contact",
      ariraLabel: "community-view-link",
    },
    {
      label: "header-blogLink",
      href: "/blog",
      ariraLabel: "blog-link",
    },
    {
      label: "header-jobsLink",
      href: "/jobs",
      ariraLabel: "jobs-link",
    },
  ],
  links: {
    github: "https://github.com/nextui-org/nextui",
    twitter: "https://twitter.com/getnextui",
    docs: "https://nextui.org",
    discord: "https://discord.gg/9b6yyZKmH4",
    sponsor: "https://patreon.com/jrgarciadev",
  },
};
